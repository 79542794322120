import React from 'react'
import styled from 'styled-components'

const Outer = styled.article`
  > a {
    margin-top: 20px;
  }

  .btn--pill {
    min-width: 0;
    padding: 10px 15px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    > a {
      margin-top: 30px;
    }
  }
`

const Header = styled.div`
  text-align: center;
  padding-top: 15px;

  .gatsby-image-wrapper {
    max-width: 170px;
    max-height: 170px;
    margin-bottom: 24px;

    &,
    img {
      border-radius: 50%;
    }
  }
`

const Title = styled.h3`
  margin-bottom: 20px;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
  }
`

const Body = styled.div`
  strong {
    font-weight: 600;
  }
`

const BasicModal = ({ name, body }) => {
  return (
    <Outer>
      <Header>
        <Title className="headline--medium" title={name}>
          {name}
        </Title>
      </Header>
      {body && (
        <Body
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      )}
    </Outer>
  )
}

export default BasicModal
