import React from 'react'
import styled from 'styled-components'
import BasicModal from '../../components/BasicModal'
import Modal from '../../components/Modal'
import ToggleModal from '../../components/ToggleModal'
import Videos from '../../components/Videos'

const Container = styled.section`
  width: 100%;
  max-width: ${props => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
`

const CultureVideos = ({ pageContext: { videos } }) => (
  <Container className="section">
    <ToggleModal
      key="seint-culture"
      toggle={show => (
        <div style={{ textAlign: 'center' }}>
          <button class="btn--pill" style={{ borderRadius: '0', borderColor: '#eee' }} onClick={show}>
            Culture of Abundance<br />
            (click to read)
          </button>
        </div>
      )}
      content={(hide, isShown) => (
        <Modal key="seint-culture" hide={hide} isShown={isShown}>
          <BasicModal name="Seint Culture" body="<p>When Cara first started, culture wasn't something she consciously focused on, but as her beauty blog flourished, she quickly realized that a special kind of community was forming. The readers of her blog were not only learning from her but supporting each other. Her blog became a welcoming space for women to discover easy beauty tips while feeling confident and uplifted. This sense of community and kindness became the foundation of what Cara valued most, even more than financial success—it was about creating a space where people felt seen and supported.</p>
            <p>As Seint grew, Cara understood how essential a strong company culture was to the overall success and happiness of both the Artists and customers. She believes that a positive culture brings a sense of joy, fulfillment, and lasting connection to the work. At Seint, the culture is inclusive, welcoming everyone from Artists to customers. For those who don't naturally align with the culture, it's okay—those individuals move on, which only strengthens the sense of camaraderie among those who stay and continue to build the supportive environment.</p>
            <p>Generosity is one of the core values at Seint, something Cara has always embodied. She has never hesitated to share her knowledge and experience with others, and believes that generosity attracts more generosity. Creativity is another key pillar—Cara encourages everyone to tap into their creative potential, believing that creativity lives within everyone, even if they don't always see it in themselves.</p>
            <p>Alongside these values, integrity, curiosity, and hard work are fundamental elements that drive Seint's culture. Together, they have helped foster a unique, growing community that supports and uplifts one another, which Cara sees as essential for the company's long-term success. Seint's culture is more than just a set of ideals—it's the heart of everything they do, keeping the purpose alive and ensuring the company continues to thrive.</p>" />
        </Modal>
      )}
    />
    <Videos className="section" videos={videos} />
  </Container >
)

export default CultureVideos
